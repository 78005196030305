<template>
  <div class="pp-score-requests__component">
    <o-table
      class="pp-table__requests"
      :data="localData"
      hoverable
      :mobile-cards="false"
    >
      <o-table-column
        v-slot="props"
        field="riskScore"
        label="Score"
        sortable
      >
        <div
          v-if="props.row.riskScore === null"
          class="dark-color"
        >
          --
        </div>
        <GlBadgeInfo
          v-else-if="!loading"
          :entity-id="null"
          :text="null"
          :value="props.row.riskScore ? props.row.riskScore.toFixed(0) : 0"
        />
      </o-table-column>
      <o-table-column
        v-slot="props"
        field="tx"
        label="Transaction"
        sortable
      >
        <div
          class="link"
          @click="openInNewTab(props.row.tx)"
        >
          {{ trancateString(props.row.tx, isMobile || isTablet ? 6 : 200) }}
        </div>
      </o-table-column>
      <o-table-column
        v-slot="props"
        field="timestamp"
        label="Date & Time"
        sortable
      >
        <div v-html="formatDate(props.row.timestamp, 'dd.MM.yyyy HH:mm:SS', true)" />
      </o-table-column>
      <o-table-column
        v-slot="props"
        field="amount"
        label="Amount"
        sortable
      >
        <div class="pp-table__amount">
          {{ props.row.amountText
            ? props.row.amountText
            : formatBtcAmount(props.row.amount, true, $can('use', 'btc') ? '' : props.row.ticker) || '--' }}
        </div>
      </o-table-column>
      <o-table-column
        v-slot="props"
        field="tokens"
        label="Tokens"
        sortable
        :visible="$can('use', 'eth')"
      >
        {{ props.row.tokens || '--' }}
      </o-table-column>
      <template #empty>
        <div
          class="empty-users-data flex column align-center"
        >
          <gl-icon
            :height="24"
            name="statistic"
            :width="24"
          />
          No data here yet
        </div>
      </template>
    </o-table>
    <div class="pp-pagination-table">
      <div class="pp-pagination-table__left">
        <div class="pp-pagination-table__text">
          Rows per page:
        </div>
        <div class="pp-pagination-table__select">
          <vSelect
            v-model="perPage"
            class="select--top-direction"
            :clearable="false"
            :options="pagesOptions"
            :searchable="false"
            @input="countChange"
          />
        </div>
      </div>
      <div 
        v-if="localData.length && totalPages > 1" 
        class="pp-pagination-table__right"
      >
        <o-pagination
          :current.sync="currentPage"
          :per-page="perPage"
          :range-after="0"
          :range-before="0"
          :total="total"
          @change="pageChange"
        >
          <template #default="props">
            <div
              v-if="props.page.isCurrent"
              class="pp-pagination-table__pages"
            >
              {{ `${props.page.number} of ${totalPages}` }}
            </div>
          </template>
          <template #previous="props">
            <div class="pp-pagination-table__button-prev">
              <o-pagination-button
                :page="props.page"
              >
                <gl-menu-item
                  :disabled="props.page.disabled"
                  icon="left"
                  :icon-height="24"
                  :icon-width="24"
                  label=""
                  not-outline
                />
              </o-pagination-button>
              <div class="pp-pagination-table__current">
                {{ props.page.number + 1 }}
              </div>
            </div>
          </template>
          <template #next="props">
            <div class="pp-pagination-table__button-next">
              <o-pagination-button
                :page="props.page"
              >
                <gl-menu-item
                  :disabled="props.page.disabled"
                  icon="right"
                  :icon-height="24"
                  :icon-width="24"
                  label=""
                  not-outline
                />
              </o-pagination-button>
            </div>
          </template>
        </o-pagination>
      </div>
    </div>
    <div
      v-show="loading"
      class="pp-score-requests__loader"
      style="z-index: 10"
    >
      <gl-loader />
    </div>
  </div>
</template>

<script>
//Vuex
import {mapActions, mapState} from "vuex";
// Components
import GlMenuItem from '@/components/gl-menu-item'
import vSelect from 'vue-select'
import GlLoader from '@/components/gl-loader'
import GlIcon from '@/components/gl-icon'
import GlBadgeInfo from '@/components/gl-badge-info'
// Utils
import { trancateString } from "@/utils/text-formatter"
import { formatBtcAmount } from '@/utils/format-btc-amount'
import { formatDate } from "@/utils/format-date"
// Mixin
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  name: 'TxScoring',
  components: {
    GlMenuItem,
    vSelect,
    GlIcon,
    GlLoader,
    GlBadgeInfo,
  },
  mixins: [deviceWidthMixin],
  data() {
    return {
      loading: false,
      pagesOptions: [5, 10,20, 50, 100],
      currentPage: 1,
      perPage: 10,
      totalPages: 1,
      total: 1,
      localData: [],
    }
  },
  computed: {
    ...mapState('analytics', ['coinType', 'coinData'])
  },
  watch: {
    coinType: {
      handler() {
        this.loadData()
      },
    },
  },
  created() {
    this.perPage = localStorage.getItem('tx-api-call-per-page') || this.perPage
    localStorage.setItem('tx-api-call-per-page', this.perPage)
  },
  mounted() {
    this.loadData()
  },
  methods: {
    formatDate,
    trancateString,
    formatBtcAmount,
    ...mapActions({
      getRiskTxHistoryList: 'analytics/getRiskTxHistoryList',
      getCurrencyInfo: 'analytics/getCurrencyInfo',
    }),
    openInNewTab(tx) {
      const { href } = this.$router.resolve({ name: 'report', query: { tx, type: this.coinType } })
      window.open(href, '_blank')
    },
    async loadData() {
      this.loading = true
      const sendParams = this.formattedSendData()
      await this.getRiskTxHistoryList(sendParams).then(( { data: { items, totalItems } } ) => {
        const tokens = items.map(el => {
          if (el.token) {
            return el.token
          }
        }).filter(item => item)

        const uniqTokens = [...new Set(tokens)]

        if (uniqTokens.length) {
          this.getCurrencyInfo({ address: uniqTokens }).then((currencies) => {
            this.localData = items.map(el => {
              const localCurrency = currencies[el.token]
                ? { ...currencies[el.token], decimals: currencies[el.token].decimals || 0 }
                : {}

              return {
                ...el,
                amountText: this.formatBtcAmount(el.amount / Math.pow(10, localCurrency.decimals ?? this.coinData.decimals ?? 0), true,  this.coinData.key, localCurrency.currency || this.coinData.label),
              }
            })
          })
        } else {

          if (this.coinData.family === 'eth') {
            this.localData = items.map(el => {
              return {
                ...el,
                amountText: this.formatBtcAmount(el.amount / Math.pow(10, this.coinData.decimals ?? 0), true,  this.coinData.key, this.coinData.label),
              }
            })
          } else {
            this.localData = items
          }
        }

        this.total = totalItems
        this.totalPages = Math.ceil(this.total / this.perPage)
        this.checkedRows = []
      }).finally(() => {
        this.loading = false
      })
    },
    formattedSendData() {
      return {
        count: this.perPage,
        search: this.search,
        skip: (this.currentPage - 1) * this.perPage,
      }
    },
    countChange() {
      localStorage.setItem('tx-api-call-per-page', this.perPage)
      this.loadData()
    },
    pageChange(event) {
      this.currentPage = event
      this.loadData()
    },
  }
}
</script>

<style scoped>
.pp-table__amount {
  white-space: nowrap;
}
</style>