<template>
  <div class="pp-score-requests__component">
    <o-table
      class="pp-table__requests"
      :data="localData"
      hoverable
      :mobile-cards="false"
    >
      <o-table-column
        v-slot="props"
        field="riskScore"
        label="Score"
        sortable
      >
        <div
          v-if="props.row.riskScore === null"
          class="dark-color"
        >
          --
        </div>
        <GlBadgeInfo
          v-else-if="!loading"
          :entity-id="null"
          :text="null"
          :value="props.row.riskScore ? props.row.riskScore.toFixed(0) : 0"
        />
      </o-table-column>
      <o-table-column
        v-slot="props"
        field="address"
        label="Address"
        sortable
      >
        <div
          class="link"
          @click="openInNewTab(props.row.address.address ? props.row.address.address : props.row.address)"
        >
          {{ props.row.address.address ? trancateString(props.row.address.address, isMobile || isTablet ? 6 : 200) : trancateString(props.row.address, isMobile || isTablet ? 6 : 200) }}
        </div>
      </o-table-column>
      <o-table-column
        v-slot="props"
        field="timestamp"
        label="Date & Time"
        sortable
      >
        <div v-html="formatDate(props.row.timestamp, 'dd.MM.yyyy HH:mm:SS', true)" />
      </o-table-column>
      <o-table-column
        v-slot="props"
        field="token_ticker"
        label="Token"
        sortable
        :visible="$can('use', 'eth')"
      >
        {{ props.row.tokenTicker || '--' }}
      </o-table-column>
      <template #empty>
        <div
          class="empty-users-data flex column align-center"
        >
          <gl-icon
            :height="24"
            name="statistic"
            :width="24"
          />
          No data here yet
        </div>
      </template>
    </o-table>
    <div class="pp-pagination-table">
      <div class="pp-pagination-table__left">
        <div class="pp-pagination-table__text">
          Rows per page:
        </div>
        <div class="pp-pagination-table__select">
          <vSelect
            v-model="perPage"
            class="select--top-direction"
            :clearable="false"
            :options="pagesOptions"
            :searchable="false"
            @input="countChange"
          />
        </div>
      </div>
      <div 
        v-if="localData.length && totalPages > 1" 
        class="pp-pagination-table__right"
      >
        <o-pagination
          :current.sync="currentPage"
          :per-page="perPage"
          :range-after="0"
          :range-before="0"
          :total="total"
          @change="pageChange"
        >
          <template #default="props">
            <div
              v-if="props.page.isCurrent"
              class="pp-pagination-table__pages"
            >
              {{ `${props.page.number} of ${totalPages}` }}
            </div>
          </template>
          <template #previous="props">
            <div class="pp-pagination-table__button-prev">
              <o-pagination-button
                :page="props.page"
              >
                <gl-menu-item
                  :disabled="props.page.disabled"
                  icon="left"
                  :icon-height="24"
                  :icon-width="24"
                  label=""
                  not-outline
                />
              </o-pagination-button>
              <div class="pp-pagination-table__current">
                {{ props.page.number + 1 }}
              </div>
            </div>
          </template>
          <template #next="props">
            <div class="pp-pagination-table__button-next">
              <o-pagination-button
                :page="props.page"
              >
                <gl-menu-item
                  :disabled="props.page.disabled"
                  icon="right"
                  :icon-height="24"
                  :icon-width="24"
                  label=""
                  not-outline
                />
              </o-pagination-button>
            </div>
          </template>
        </o-pagination>
      </div>
    </div>
    <div
      v-show="loading"
      class="pp-score-requests__loader"
      style="z-index: 10"
    >
      <gl-loader />
    </div>
  </div>
</template>

<script>
//Vuex
import { mapActions, mapState } from "vuex";
// Components
import vSelect from 'vue-select'
import GlIcon from '@/components/gl-icon'
import GlLoader from '@/components/gl-loader'
import GlMenuItem from '@/components/gl-menu-item'
import GlBadgeInfo from '@/components/gl-badge-info'
// Utils
import { formatDate } from "@/utils/format-date"
import { trancateString } from "@/utils/text-formatter"
// Mixin
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  name: 'TagsScoring',
  components: {
    GlIcon,
    vSelect,
    GlLoader,
    GlMenuItem,
    GlBadgeInfo,
  },
  mixins: [deviceWidthMixin],
  data() {
    return {
      total: 1,
      perPage: 10,
      localData: [],
      totalPages: 1,
      loading: false,
      currentPage: 1,
      pagesOptions: [5, 10,20, 50, 100],
    }
  },
  computed: {
    ...mapState('analytics', ['coinType'])
  },
  watch: {
    coinType: {
      handler() {
        this.loadData()
      },
    },
  },
  created() {
    this.perPage = localStorage.getItem('address-api-call-per-page') || this.perPage
    localStorage.setItem('address-api-call-per-page', this.perPage)
  },
  mounted() {
    this.loadData()
  },
  methods: {
    formatDate,
    trancateString,
    ...mapActions({
      getRiskAddressHistoryList: 'analytics/getRiskAddressHistoryList',
    }),
    async loadData() {
      this.loading = true
      const sendParams = this.formattedSendData()
      await this.getRiskAddressHistoryList(sendParams).then(( { data: { items, totalItems } }  ) => {
        this.localData = items
        this.total = totalItems
        this.totalPages = Math.ceil(this.total / this.perPage)
        this.checkedRows = []
      }).finally(() => {
        this.loading = false
      })
    },
    openInNewTab(address) {
      const { href } = this.$router.resolve({ name: 'report', query: { address, type: this.coinType } })
      window.open(href, '_blank')
    },
    formattedSendData() {
      return {
        count: this.perPage,
        search: this.search,
        skip: (this.currentPage - 1) * this.perPage,
      }
    },
    countChange() {
      localStorage.setItem('address-api-call-per-page', this.perPage)
      this.loadData()
    },
    pageChange(event) {
      this.currentPage = event
      this.loadData()
    },
  }
}
</script>
